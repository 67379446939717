<template>
    <el-drawer v-model="drawer" :title="name" direction="ltr" size="40%">
        <!-- 时间查询 -->
        <el-row>
            <el-date-picker v-model="SearchDate" type="daterange" range-separator="To" start-placeholder="起始时间"
                end-placeholder="结束时间" format="YYYY/MM/DD">
            </el-date-picker>
            <!-- 查询按钮 -->
            <el-button :icon="Search" style="margin-left:50px" type="success" @click="SearchClick()" circle></el-button>
        </el-row>
        <!-- 数据表格 -->

        <el-table :data="tableData" style="width: 100%" :height="`calc(100vh - 210px)`">
            <el-table-column prop="change_time" label="时间" width="220" />
            <el-table-column prop="old_job" label="原岗位" width="180" />
            <el-table-column prop="new_job" label="新岗位" width="180" />
            <el-table-column prop="manager" label="操作员" />
        </el-table>


        <!-- 数据分页 -->
        <el-pagination background layout="total,prev, pager, next,jumper" :total="pageStruct.total"
            :page-size="pageStruct.pagesize" :current-page="pageStruct.currentpage"
            @current-change="handleCurrentChange">
        </el-pagination>
    </el-drawer>
</template>


<script setup>
    import {
        defineExpose,
        reactive,
        ref,
        toRefs
    } from "vue"
    import {
        Search
    } from "@element-plus/icons"
    import {SelectJobHistory} from "@/api/user"

    const state = reactive({
        tableData:null
    })
    const pageStruct = reactive({
        total: 0,
        pagesize: 20,
        currentpage: 1
    })

    const SearchDate = ref(null)
    //人员名称信息
    let name = ref(null)
    //显示抽屉
    const drawer = ref(false)
    //显示函数
    let dataStruct = {
        uid: null,
        date: {start:null,end:null}
    }
    const showModal = (isShow, data) => {
        state.tableData = null
        if (isShow) {
            drawer.value = true
            name.value = `${data.name}---岗位变化信息`
            dataStruct.uid = data.id
            pageStruct.total = 0
            pageStruct.currentpage = 1
        }
    }

 //分页变化
 const handleCurrentChange = (page) => {
 pageStruct.currentpage = page
 SearchClick()
 }
    //查询数据
    const SearchClick = () => {
        dataStruct.date.start = SearchDate.value[0]
        dataStruct.date.end = SearchDate.value[1]
        SelectJobHistory(dataStruct,pageStruct.currentpage).then((response)=>{
            if (response.code != 200) {
            return false;
            }
           state.tableData = response.data.list
           pageStruct.total = response.data.count
        })
    }
 //导出属性到页面中使用
 let {
 tableData
 } = toRefs(state);
    //导出变量函数
    defineExpose({
        showModal
    });
</script>

<style>

</style>