<template>
  <div class="top">
    <el-button type="primary" size="mini" :icon="FolderAdd" @click="OpenForm()">新增</el-button>
    <el-form :model="form" size="mini" :inline="true" style="margin-left: 20px;">
      <!----------搜索表格-------->
      <el-form-item>
        <el-input v-model="form.name" class="w-50 m-2" size="small" placeholder="姓名/身份证号码" :prefix-icon="Search" />
      </el-form-item>

      <el-form-item label="">
        <el-select v-model="form.team" placeholder="选择中队">
          <el-option label="全部" value=0></el-option>
          <el-option v-for="item in TeamList" :key="item.id" :label="item.team_name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSearch">查询</el-button>
      </el-form-item>


    </el-form>
  </div>
  <UserInfo ref="showInfo" />
  <UserAdd ref="showForm" @fetchData="fetchData" />
  <JobHistory ref="showJobHistory" />
  <el-table :data="tableData" size="small" :height="`calc(100vh - 210px)`" :highlight-current-row="true"
    style="width: 100%" :row-class-name="TableRowStyle">
    <el-table-column prop="id" label="id" width="60" />
    <el-table-column prop="name" align="center" label="姓名">

      <template v-slot="scope">

        <span v-if="scope.row.policital_status == 0">

          <el-tooltip class="box-item" content="中国共产党党员" placement="right-start" effect="customized">
            <span @click="tableCatClick(scope.row)">{{ scope.row.name }}
              <svg-icon icon-class="dh" class-name="card-panel-icon" />
            </span>

          </el-tooltip>

        </span>
        <span v-else @click="tableCatClick(scope.row)"> {{ scope.row.name }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="sex" align="center" label="性别">
      <template v-slot="scope">
        <span>{{ scope.row.sex == "0" ? "女" : "男" }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="work_id" align="center" label="保安编号" />
    <el-table-column prop="phone" align="center" label="联系号码" />
    <el-table-column prop="workinfo.is_onjob" align="center" label="在职状态" width="120">
      <template v-slot="scope">
        {{ scope.row.workinfo.is_onjob == "0" ? "离职" : "在职" }}
      </template>
    </el-table-column>
    <el-table-column prop="workinfo.org" align="center" label="服务单位" />
    <el-table-column prop="workinfo.team" align="center" label="所属中队" />


    <el-table-column label="操作" align="center">
      <template v-slot="scope">
        <el-dropdown size="small"><span class="el-dropdown-link">操作</span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="openJobForm(scope.row)">岗位历史变动</el-dropdown-item>
              <el-dropdown-item @click="tableCatClick(scope.row)">查看</el-dropdown-item>
              <el-dropdown-item @click="tableEditClick(scope.row)">编辑</el-dropdown-item>
              <el-dropdown-item>
                <el-popconfirm title="是否删除该用户?请谨慎操作!" @confirm="tableDelClick(scope.row)">
                  <template #reference>
                    <span>删除</span>
                  </template>
                </el-popconfirm>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </template>
    </el-table-column>







  </el-table>

  <el-pagination background layout="total,prev, pager, next,jumper" :total="pageStruct.total"
    :page-size="pageStruct.pagesize" :current-page="pageStruct.currentpage" @current-change="handleCurrentChange">
  </el-pagination>
</template>


<script setup>
import { toRefs, reactive, onBeforeMount, ref, getCurrentInstance, nextTick } from "vue";
import { getUserList, getUserInfo, DeleteUser } from "@/api/user";
import { Search, FolderAdd } from "@element-plus/icons";
import UserInfo from "../User/components/UserInfo.vue"
import UserAdd from "../User/components/UserAdd.vue"
import JobHistory from "../User/components/JobHistory.vue"
import { getTeamList } from "@/api/unit.js"
//import UserInfo from "../components/UserInfo.vue";
let { proxy } = getCurrentInstance();
const showForm = ref(null);
const showInfo = ref(null);


const TableRowStyle = (row) => {
  if (row.row.workinfo.is_onjob == 0)
    return "no_online"
}

//------------搜索变量---------
const form = reactive({
  name: null,
  team: "0",
})
const onSearch = () => {
  pageStruct.currentpage = 1
  fetchData()
}
//--------------调岗记录----------------------
const showJobHistory = ref(null);
//查看
let openJobForm = (row) => {
  proxy.$nextTick(() => {
    showJobHistory.value.showModal(true, row)
  })
}
//-------------------------------------------

//添加 查看 修改
let OpenForm = () => {
  proxy.$nextTick(() => {
    showForm.value.showModal();
  });
};
//查看
// let pagesize = 20;
let pageStruct = reactive({
  currentpage: 1,
  total: 0,
  pagesize: 20
})
let tableCatClick = (row) => {
  getUserInfo(row.id).then((response) => {
    if (response.code != 200) {
      return false;
    }
    nextTick(() => {
      showInfo.value.showModal(response.data);
    });
  });
};
//修改
let tableEditClick = (row) => {
  getUserInfo(row.id).then((response) => {
    if (response.code != 200) {
      return false;
    }
    proxy.$nextTick(() => {
      showForm.value.showModal(true, response.data, false);
    });
  });
};
//删除
let tableDelClick = (row) => {
  DeleteUser(row.id).then((response) => {
    showForm.value.eleMessage(response)
    fetchData()
  });
};

const state = reactive({
  tableData: null,
  TeamList: null
});
onBeforeMount(() => {
  getTeamList().then(res => {
    state.TeamList = res.data;
  })

  fetchData()

});
const fetchData = () => {
  getUserList(pageStruct.currentpage, form).then((response) => {
    state.tableData = response.data.list;
    pageStruct.total = response.data.count
  });
};
//分页变化
const handleCurrentChange = (page) => {
  pageStruct.currentpage = page
  fetchData()
}

//导出属性到页面中使用
let {
  tableData,
  TeamList
} = toRefs(state);
</script>

<style>
.top {
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  align-items: center;
}

.el-pagination {
  text-align: center;
}

.el-table tbody tr:hover>td {
  background-color: #fff;
  color: black !important;
}

.el-table .no_online {
  background: #FF4500 !important;
  color: #fff;
}

/*  .el-table .current-row{
    color:#000;
    background: #FF4500;
  } */
.el-table__body tr.current-row>td {
  background-color: #66B1FF !important;
  color: #000;



}

/* 用来设置当前页面element全局table 鼠标移入某行时的背景色*/

.el-table--enable-row-hover .el-table__body tr:hover>td {
  background-color: #e2e2e1;
  background-color: #ccc !important;


}


.el-popper.is-customized {
  /* Set padding to ensure the height is 32px */
  padding: 6px 12px;
  background: linear-gradient(90deg, rgb(255, 238, 0), rgb(255, 1, 1));
}

.el-popper.is-customized .el-popper__arrow::before {
  background: linear-gradient(45deg, rgb(255, 238, 0), rgb(255, 238, 0));
  right: 0;
}

.el-input--small .el-input__inner {
  height: 28px;
  line-height: 28px;
}
</style>
