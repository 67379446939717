<template>
  <el-dialog
    v-model="centerDialogVisible"
    :title="dialogTitle"
    width="70%"
    :close-on-click-modal="false"
  >
    <el-form
      size="mini"
      label-width="auto"
      ref="refForm"
      :disabled="Formdisabled"
      :model="subForm"
      :rules="formRulesMixin"
    >
      <el-card class="box-card">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item
              label="姓名"
              prop="name"
              :rules="formRulesMixin.isNotNull"
            >
              <el-input
                v-model="subForm.name"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="手机号码"
              prop="phone"
              :rules="formRulesMixin.mobile"
            >
              <el-input
                v-model="subForm.phone"
                placeholder="请输入手机号码"
              ></el-input>
            </el-form-item>
             <el-form-item
              label="身份证号码"
              prop="idcard"
              :rules="formRulesMixin.credentialsCode"
            >
              <el-input
                v-model="subForm.idcard"
                placeholder="请输入身份证号码"
              ></el-input>
            </el-form-item>
            <el-form-item label="曾用名" prop="used_name">
              <el-input
                v-model="subForm.used_name"
                placeholder="请输入曾用名"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="民族"
              prop="ethnic_group"
              :rules="formRulesMixin.isNotNull"
            >
              <el-input
                v-model="subForm.ethnic_group"
                placeholder="请输入民族"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="血型"
              prop="blood_type"
              :rules="formRulesMixin.isNotNull"
            >
              <el-input
                v-model="subForm.blood_type"
                placeholder="请输入血型"
              ></el-input>
            </el-form-item>
            <el-form-item label="身高(CM)" prop="height">
              <el-input
                v-model="subForm.height"
                placeholder="请输入身高"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="政治面貌"
              prop="policital_status"
              :rules="formRulesMixin.isNotNull"
            >
              <el-select
                placeholder="请选择"
                v-model="subForm.policital_status"
              >
                <el-option
                  v-for="item in options.politics"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="婚姻状况"
              prop="marital_status"
              :rules="formRulesMixin.upZeroIntCanNull"
            >
              <el-select placeholder="请选择" v-model="subForm.marital_status">
                <el-option
                  v-for="item in options.marriage"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="兵役状况"
              prop="military_status"
              :rules="formRulesMixin.upZeroIntCanNull"
            >
              <el-select placeholder="请选择" v-model="subForm.military_status">
                <el-option
                  v-for="item in options.soldier"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="性别"
              prop="sex"
              :rules="formRulesMixin.ZeroIntCanNull"
            >
              <el-select placeholder="请选择" v-model="subForm.sex">
                <el-option
                  v-for="item in options.sex"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="文化程度"
              prop="edu_level"
              :rules="formRulesMixin.ZeroIntCanNull"
            >
              <el-select placeholder="请选择" v-model="subForm.edu_level">
                <el-option
                  v-for="item in options.edu"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="健康状况" prop="health_status">
              <el-input
                placeholder="请输入健康状况"
                v-model="subForm.health_status"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="胸牌编号"
              prop="work_id"
            >
              <el-input
                placeholder="请输入胸牌编号"
                v-model="subForm.work_id"
              ></el-input>
            </el-form-item>
            <el-form-item label="保安证编号" prop="safe_id">
              <el-input
                placeholder="请输入保安证编号"
                v-model="subForm.safe_id"
              ></el-input>
            </el-form-item>
              <el-form-item label="出生日期" prop="birthday">
                <el-date-picker type="date" placeholder="请选择日期" v-model="subForm.birthday" value-format="x">
                </el-date-picker>
              </el-form-item>
            <el-form-item
              label="籍贯"
              prop="native_place"
              :rules="formRulesMixin.isNotNull"
            >
              <el-input
                placeholder="请输入籍贯"
                v-model="subForm.native_place"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--avatar-->

            <el-form-item prop="avatar_path">
              <el-image
                style="width: 100px; height: 100px"
                :src="avatar_img"
                :preview-src-list="srcList"
              >
              </el-image>
            </el-form-item>
            <el-form-item prop="native_place">
              <el-upload
                class="upload-demo"
                :action="baseurl + '/api/admin/common/image/upload'"
                name="image"
                :on-success="handleUpSuccess"
                :headers="header"
              >
                <el-button type="primary" size="mini">上传头像</el-button>
              </el-upload>
              <!--需要添加上传头像功能-->
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-row>
            </el-row>
            <el-row>
              <el-form-item
                label="户籍地详址"
                style="width: 50%"
                prop="census_address"
                :rules="formRulesMixin.isNotNull"
              >
                <el-input
                  placeholder="请输入户籍地详址"
                  v-model="subForm.census_address"
                ></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item
                label="现住地详址"
                style="width: 50%"
                prop="residence_address"
                :rules="formRulesMixin.isNotNull"
              >
                <el-input
                  placeholder="请输入现住地详址"
                  v-model="subForm.residence_address"
                ></el-input>
              </el-form-item>
            </el-row>
            <el-row>
      
            </el-row>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item
              label="服务单位"
              prop="workinfo.org"
              :rules="formRulesMixin.isNotNull"
            >
              <!--网络获取-->
              <el-cascader
                v-model="subForm.workinfo.org"
                :options="struct.list"
                :props="{ expandTrigger: 'hover', label: 'name', value: 'id' }"
              ></el-cascader>
            </el-form-item>
            <el-form-item
              label="在职状态"
              prop="workinfo.is_onjob"
              :rules="formRulesMixin.ZeroIntCanNull"
            >
              <el-select
                placeholder="请选择"
                v-model="subForm.workinfo.is_onjob"
              >
                <el-option label="在职" :value="1"></el-option>
                <el-option label="离职" :value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="招聘方式"
              prop="workinfo.recruitment_type"
              :rules="formRulesMixin.isNotNull"
            >
              <!--网络获取-->
              <el-select
                placeholder="请选择"
                v-model="subForm.workinfo.recruitment_type"
              >
                <el-option
                  v-for="item in struct.recruit"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="入职时间"
              prop="workinfo.entry_date"
              :rules="formRulesMixin.isNotNull"
            >
              <el-date-picker
                type="date"
                placeholder="请选择日期"
                v-model="subForm.workinfo.entry_date"
                value-format="x"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="离职时间" prop="workinfo.leave_date">
              <el-date-picker
                type="date"
                placeholder="请选择日期"
                v-model="subForm.workinfo.leave_date"
                value-format="x"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-divider></el-divider>
    <template #footer v-if="isHiddenButton">
      <span class="dialog-footer">
        <el-button type="primary" @click="AddForm" size="mini">提交</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import {
  defineExpose,
  getCurrentInstance,
  reactive,
  ref,
  onBeforeMount,
  toRefs,
  defineEmits,
} from "vue";
import { options } from "@/utils/options";
// eslint-disable-next-line no-unused-vars
import { getUserStruct, AddUser, UpdateUser } from "@/api/user";
import { ElNotification } from "element-plus";
import { baseurl } from "@/settings";
const emit = defineEmits(["fetchData"]);
const state = reactive({
  struct: null,
});

let header = {
  Authorization: "Bearer " + localStorage.getItem("Admin-Token"),
};

onBeforeMount(() => {
  getUserStruct().then((res) => {
    state.struct = res.data;
  });
});
let { struct } = toRefs(state);
let { proxy } = getCurrentInstance();
let subForm = reactive({
  id: "",
  phone: "",
  idcard: "",
  name: "",
  used_name: "",
  ethnic_group: "",
  blood_type: "",
  height: "",
  policital_status: "",
  marital_status: "",
  military_status: "",
  sex: "",
  edu_level: "",
  health_status: "",
  work_id: "",
  safe_id: "",
  birthday: "",
  native_place: "",
  avatar_path: "",
  census_address: "",
  residence_address: "",
  workinfo: {
    org: "",
    entry_date: "",
    leave_date: "",
    is_onjob: "",
    recruitment_type: "",
  },
});
//是否显示Dialog
let centerDialogVisible = ref(false);
let Formdisabled = ref(false)
let avatar_img = ref("");
let isHiddenButton = ref(true);
//初始化Dialog标题
let dialogTitle = ref("");
//编辑框相关
const showModal = (isEdit, insertdata, isCat) => {
  if (isEdit) {
    isHiddenButton.value = true;

    dialogTitle.value = `编辑${insertdata.name}`;
    centerDialogVisible.value = true
    Formdisabled.value = false
    reshowData(insertdata);
    avatar_img.value = baseurl + insertdata.avatar_path;
    srcList[0] = baseurl + insertdata.avatar_path;
  } else if (isCat) {
    isHiddenButton.value = false;
    dialogTitle.value = `${insertdata.name}的个人信息`;
    centerDialogVisible.value = true
    Formdisabled.value = true;
    reshowData(insertdata);
    avatar_img.value = baseurl + insertdata.avatar_path;
    srcList[0] = baseurl + insertdata.avatar_path;
  } else {
    subForm.id = ''
    isHiddenButton.value = true;
    dialogTitle.value = "添加人员信息";
    centerDialogVisible.value = true
    Formdisabled.value = false
    proxy.$nextTick(() => {
      if (proxy.$refs["refForm"] != undefined) {
     proxy.$refs["refForm"].resetFields();
 }
    });
  }
};

//修改
const reshowData = (row) => {
  proxy.$nextTick(() => {
    proxy.$refs["refForm"].resetFields();
    Object.keys(row).forEach((fItem) => {
      Object.keys(subForm).forEach((sItem) => {
        if (fItem === sItem) {
          subForm[sItem] = row[sItem];
        }else if(row[sItem] == undefined){
          subForm[sItem] = ' ';
        }
      });
    });
  });
};
//头像上传成功后的回调函数
const handleUpSuccess = (response) => {
  console.log(response);
  subForm.avatar_path = response.path;
  avatar_img.value = baseurl + response.path;
  srcList[0] = baseurl + response.path;
  console.log(srcList);
};

const refForm = ref(null);
const eleMessage =(res) =>{
    let type = 'success'
    if(res.code !=200){
      type = 'error'
    }else if(res.code == 200){
      type = 'success'
      centerDialogVisible.value = false;
      emit("fetchData");
    }
     ElNotification({
            title: "提示",
            message: res.msg,
            type: type,
          });
}
//提交表单 校验
let AddForm = () => {
  refForm.value.validate((valid) => {
    if (valid) {
      //校验通过后的操作
      if (subForm.id) {
        
         //   updateReq()
        UpdateUser(subForm).then((res) => {
         eleMessage(res)
          
        });
       
      } else {
        
        //  insertReq()
        
        AddUser(subForm).then((res) => {
          eleMessage(res)
         
        });
      }
    } else {
      return false;
    }
  });
};

defineExpose({
  showModal,
  eleMessage
});

const srcList = reactive([]);
</script>




<style lang="scss" scoped>
.box-card {
  margin-bottom: 5px;
}

.dialog-footer {
  text-align: right;
}

.el-dialog__footer {
  padding: var(--el-dialog-padding-primary);
  padding-top: 10px;
  text-align: right;
  box-sizing: border-box;
}
</style>